export abstract class Loader {
  
  public API_KEY: string = 'f1e5a1b0bb7754ee';

  constructor () {
    document.addEventListener("DOMContentLoaded", this.init.bind(this));
    document.body.querySelector('.js-add').addEventListener('click', this.clickHandler.bind(this));
  }

  abstract init (): void;

  abstract onError(error: any): void;

  private clickHandler () {
    window.open(window.location.href, '_blank');
  }
}
