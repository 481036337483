import { Loader } from './loader';
import '../static/styles/public.scss';

class TeleportLoader extends Loader {
  constructor () {
    super();
  }

  async init () {
    try {
      const instance = await window.teleport.initialize({
        apiKey: this.API_KEY,
        loader: {
          type: 'clappr-hls',
        },
      });

      const TeleportClapprHlsPlugin = instance.getLoader().getPlugin();
      const devTools = new window.DevTools(instance, document.querySelector('#dev-tools'));
      let player = new window.Clappr.Player({
        source: 'https://stream.teleport.media/hls/video.m3u8',
        autoPlay: true,
        mute: true,
        plugins: [
          TeleportClapprHlsPlugin
        ],
        parentId: '#video'
      });
    } catch (error) {
      this.onError(error);
    }
  }

  onError (error) {
    console.error('Error code', error.code, 'object', error);
  }
}

const demo = new TeleportLoader();
